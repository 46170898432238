

















































import { defineComponent, reactive, watch } from "@vue/composition-api";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(props) {
    const state = reactive({
      designatedReviewer: false,
      notDesignatedReviewer: false,
      fullyReviewed: false,
      notFullyReviewed: false,
    });

    watch(
      () => state.designatedReviewer,
      () => {
        if (state.designatedReviewer) {
          props.value.designatedReviewer.values = "null";
          state.notDesignatedReviewer = false;
          props.value.notDesignatedReviewer.values = [];
        }
      }
    );
    watch(
      () => state.notDesignatedReviewer,
      () => {
        if (state.notDesignatedReviewer) {
          props.value.notDesignatedReviewer.values = "null";
          state.designatedReviewer = false;
          props.value.designatedReviewer.values = [];
        }
      }
    );
    watch(
      () => state.fullyReviewed,
      () => {
        if (state.fullyReviewed) {
          props.value.fullyReviewed.values = ["reviewers", "review"];
          state.notFullyReviewed = false;
          props.value.notFullyReviewed.values = [];
        }
      }
    );
    watch(
      () => state.notFullyReviewed,
      () => {
        if (state.notFullyReviewed) {
          props.value.notFullyReviewed.values = ["reviewers"];
          state.fullyReviewed = false;
          props.value.fullyReviewed.values = [];
        }
      }
    );

    return { state };
  },
});
